import Head from "next/head";
import styles from "@/styles/Home.module.css";
import { useRouter } from "next/router";
import Link from "next/link";

export default function Home() {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Registrace na seminář - Vysočina Education</title>
        <meta
          name="description"
          content="Registrace na seminář - Vysočina Education"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/logo.png" />
      </Head>
      <main className={styles.main}>
        <h1>Vysočina Education - semináře</h1>
        <Link href={"/seminar/register/22"}>Přejít na seminář 22</Link>
      </main>
    </>
  );
}
